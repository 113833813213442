import React, { useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';

const LazyImage: React.FunctionComponent<any> = (props) => {
  const imageRef = useRef();
  const [load, setLoad] = useState(false);
  const [isDelete, setDelete] = useState(false);
  const { ref, inView } = useInView({
    rootMargin: '50%',
    triggerOnce: true
  });

  if (!props.data && !props.src) return <></>;
  if (props.data && !props.data.mediaItemUrl) return <></>;

  let image = (props.data && props.data.mediaItemUrl) || null;
  let width = (props.data && props.data.mediaDetails && props.data.mediaDetails.width) || null;
  let height = (props.data && props.data.mediaDetails && props.data.mediaDetails.height) || null;

  if (props.src) image = props.src;
  if (props.width) width = props.width;
  if (props.height) height = props.height;

  useEffect(() => {
    if (imageRef.current) {
      if (imageRef.current.complete) setLoad(true);
    }
    if (props.rootMargin) {
      setTimeout(() => {
        setLoad(true);
      }, 1000);
    }
  }, []);

  return (
    <>
      {!props.rootMargin && inView && (
        <img
          ref={imageRef}
          className={props.className}
          src={image}
          width={width}
          height={height}
          alt=""
          loading="lazy"
          onLoad={() => {
            setLoad(true);
          }}
        />
      )}
      {props.rootMargin && (
        <img
          ref={imageRef}
          className={props.className}
          src={image}
          width={width}
          height={height}
          alt=""
          onLoad={() => {
            setLoad(true);
          }}
        />
      )}

      {!isDelete && (
        <div
          ref={ref}
          className={`util__lazy-image-load ${load ? 'hide' : ''}`}
          onTransitionEnd={() => {
            setDelete(true);
          }}>
          <span>
            <img src="/placeholder.png" alt="" />
            <span></span>
          </span>
        </div>
      )}
    </>
  );
};
export default LazyImage;
