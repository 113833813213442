import React, { useContext, useEffect, useState } from 'react';
import { GraphQLClient, gql } from 'graphql-request';
import { Link } from 'gatsby';

import { PATH } from '../../helper/config';
import { AppContext } from '../../context/context';
import { OnlyPC, OnlySP } from '../../helper/media-helper';

import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

import 'overlayscrollbars/css/OverlayScrollbars.css';
import '../../css/cart.css';
import Util from '../../templates/utils/util';
import API from '../../templates/utils/api';

const Cart: React.FunctionComponent = () => {
  const context = useContext(AppContext);
  const [targetClass, setTargetClass] = useState<string>('');
  const [total, setTotal] = useState<number>(0);
  const endpoint = API.STORE_FRONT;
  const graphQLClient = new GraphQLClient(endpoint, {
    headers: {
      'Content-Type': 'application/json',
      'X-Shopify-Storefront-Access-Token': API.STORE_FRONT_TOKEN,
      'Accept-Language': !context.localePath.length ? 'ja-JP' : 'en-US'
    }
  });

  useEffect(() => {
    setTargetClass('show');
  }, []);

  useEffect(() => {
    let priceCount = 0;
    for (const cartItem of context.cartData.lines.edges) {
      priceCount += Number(cartItem.node.merchandise.priceV2.amount) * cartItem.node.quantity;
    }
    setTotal(priceCount);
  }, [context.cartData]);

  const checkOut = (): void => {
    const loadCheckout = async (): Promise<any> => {
      const query = gql`
        query checkoutURL($cartID: ID!) @inContext(country: US) {
          cart(id: $cartID) {
            checkoutUrl
          }
        }
      `;
      const variables = {
        cartID: context.cartId
      };
      const result = await graphQLClient.request(query, variables);
      localStorage.removeItem('cart_id');

      window.location.href = `${result.cart.checkoutUrl}${window.location.href.includes('/en/') ? '?locale=en' : ''}`;
    };

    loadCheckout();
  };

  const cleanCart = (): void => {
    localStorage.removeItem('cart_id');
    window.location.reload();
  };

  const cartInner = (
    <ul className="cart__list">
      {((): any => {
        if (!context.cartData || !context.cartData.lines || !context.cartData.lines.edges) {
          cleanCart();
          return;
        }
      })()}
      {context.cartData.lines.edges &&
        context.cartData.lines.edges.map((elem: any, i: number) => {
          return <CartListItem key={`cart-list-item-${i}`} data={elem} />;
        })}
    </ul>
  );

  return (
    <div
      className={`cart ${targetClass}`}
      onTransitionEnd={() => {
        if (targetClass === 'hide') {
          context.toggleCartWindow && context.toggleCartWindow(false);
        }
      }}>
      <div className="cart__body">
        <OnlyPC>
          <OverlayScrollbarsComponent>{cartInner}</OverlayScrollbarsComponent>
        </OnlyPC>
        <OnlySP>
          <div className="cart__body-scroll">{cartInner}</div>
        </OnlySP>
      </div>
      <div className="cart__menu">
        <div className="cart__menu-row ff-univ">
          <p className="cart__menu-caption ff-univ-bold">SUB TOTAL</p>
          <p className="cart__menu-total">
            {!context.localePath.length ? '¥' : '$'}
            {Util.getPriceString(total)}
          </p>
        </div>
        <div className="cart__menu-row ff-univ">
          <a className="cart__menu-privacy link__alpha ff-univ-bold" href={PATH.PRIVACY}>
            PRIVACY
          </a>
          <p
            className={`cart__menu-submit ff-univ-bold link__round-fill ${Number(total) === 0 ? 'disable' : ''}`}
            onPointerDown={() => {
              checkOut();
            }}>
            <span>CHECK OUT</span>
          </p>
        </div>
      </div>
      <p
        className="cart__close ff-univ-bold link__text"
        onClick={() => {
          setTargetClass('hide');
        }}>
        CLOSE
      </p>
    </div>
  );
};
export default Cart;

const CartListItem: React.FunctionComponent<any> = (props) => {
  const data = props.data.node;
  const context = useContext(AppContext);
  const [amount, setAmount] = useState<number>(data.quantity);
  const endpoint = API.STORE_FRONT;
  const graphQLClient = new GraphQLClient(endpoint, {
    headers: {
      'Content-Type': 'application/json',
      'X-Shopify-Storefront-Access-Token': API.STORE_FRONT_TOKEN,
      'Accept-Language': !context.localePath.length ? 'ja-jp' : 'en-US'
    }
  });

  useEffect(() => {
    changeQuantity();
  }, [amount]);

  const itemRemove = async (): Promise<any> => {
    if (API.CART_CONNECT) return;
    API.CART_CONNECT = true;
    const queryJa = gql`
      mutation cartLinesRemove($cartId: ID!, $lineIds: [ID!]!) @inContext(country: JP) {
        cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
          cart {
            id
            lines(first: 50) {
              edges {
                node {
                  id
                  quantity
                  merchandise {
                    ... on ProductVariant {
                      id
                      image {
                        height
                        width
                        transformedSrc
                      }
                      priceV2 {
                        amount
                        currencyCode
                      }
                      quantityAvailable
                      title
                      product {
                        handle
                        title
                      }
                    }
                  }
                }
              }
            }
          }
          userErrors {
            code
            field
            message
          }
        }
      }
    `;
    const queryEn = gql`
      mutation cartLinesRemove($cartId: ID!, $lineIds: [ID!]!) @inContext(country: US) {
        cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
          cart {
            id
            lines(first: 50) {
              edges {
                node {
                  id
                  quantity
                  merchandise {
                    ... on ProductVariant {
                      id
                      image {
                        height
                        width
                        transformedSrc
                      }
                      priceV2 {
                        amount
                        currencyCode
                      }
                      quantityAvailable
                      title
                      product {
                        handle
                        title
                      }
                    }
                  }
                }
              }
            }
          }
          userErrors {
            code
            field
            message
          }
        }
      }
    `;

    const variables = {
      cartId: context.cartId,
      lineIds: [data.id]
    };

    const changeData = await graphQLClient.request(!context.localePath.length ? queryJa : queryEn, variables);
    localStorage.setItem('cart_id', changeData.cartLinesRemove.cart.id);
    context.setCartID(changeData.cartLinesRemove.cart.id);
    context.setCartData({ ...changeData.cartLinesRemove.cart });
    API.CART_CONNECT = false;
  };

  const changeQuantity = async (): Promise<any> => {
    API.CART_CONNECT = true;

    const queryJa = gql`
      mutation cartLinesUpdate($cartId: ID!, $lines: [CartLineUpdateInput!]!) @inContext(country: JP) {
        cartLinesUpdate(cartId: $cartId, lines: $lines) {
          cart {
            id
            lines(first: 50) {
              edges {
                node {
                  id
                  quantity
                  merchandise {
                    ... on ProductVariant {
                      id
                      image {
                        height
                        width
                        transformedSrc
                      }
                      priceV2 {
                        amount
                        currencyCode
                      }
                      quantityAvailable
                      title
                      product {
                        handle
                        title
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `;
    const queryEn = gql`
      mutation cartLinesUpdate($cartId: ID!, $lines: [CartLineUpdateInput!]!) @inContext(country: US) {
        cartLinesUpdate(cartId: $cartId, lines: $lines) {
          cart {
            id
            lines(first: 50) {
              edges {
                node {
                  id
                  quantity
                  merchandise {
                    ... on ProductVariant {
                      id
                      image {
                        height
                        width
                        transformedSrc
                      }
                      priceV2 {
                        amount
                        currencyCode
                      }
                      quantityAvailable
                      title
                      product {
                        handle
                        title
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `;

    const variables = {
      cartId: context.cartId,
      lines: {
        id: data.id,
        quantity: amount
      }
    };

    const changeData = await graphQLClient.request(!context.localePath.length ? queryJa : queryEn, variables);
    localStorage.setItem('cart_id', changeData.cartLinesUpdate.cart.id);
    context.setCartID(changeData.cartLinesUpdate.cart.id);
    context.setCartData({ ...changeData.cartLinesUpdate.cart });
    API.CART_CONNECT = false;
  };

  return (
    <li className="cart__list-item">
      <div className="cart__list-item-row">
        <figure className="cart__list-item-figure">
          <img
            src={data.merchandise.image.transformedSrc}
            width={data.merchandise.image.width}
            height={data.merchandise.image.height}
            alt=""
          />
        </figure>

        <div className="cart__list-item-column ff-univ">
          <p className="cart__list-item-name ff-univ-bold">{data.merchandise.product.title}</p>
          {data.merchandise.title !== 'Default Title' && <p className="cart__list-item-meta">{data.merchandise.title}</p>}
          <p className="cart__list-item-price">
            {!context.localePath.length ? '¥' : '$'}
            {Util.getPriceString(Number(data.merchandise.priceV2.amount))}
          </p>
          <div className="cart__list-item-column-row">
            <div className="cart__list-item-amount">
              <p
                className="cart__list-item-amount-minus link__alpha"
                onClick={() => {
                  if (API.CART_CONNECT) return;
                  setAmount(Math.max(1, amount - 1));
                }}>
                -
              </p>
              <p className="cart__list-item-amount-count">{amount}</p>
              {amount < data.merchandise.quantityAvailable && (
                <p
                  className="cart__list-item-amount-plus link__alpha"
                  onClick={() => {
                    if (API.CART_CONNECT) return;
                    setAmount(amount + 1);
                  }}>
                  +
                </p>
              )}
            </div>
            <p
              className="cart__list-item-remove ff-univ-bold link__alpha"
              onClick={() => {
                itemRemove();
              }}>
              REMOVE
            </p>
          </div>
        </div>
      </div>
    </li>
  );
};
