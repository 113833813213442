// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-templates-artist-detail-tsx": () => import("./../../../src/templates/artist-detail.tsx" /* webpackChunkName: "component---src-templates-artist-detail-tsx" */),
  "component---src-templates-artist-list-tsx": () => import("./../../../src/templates/artist-list.tsx" /* webpackChunkName: "component---src-templates-artist-list-tsx" */),
  "component---src-templates-dictionary-detail-tsx": () => import("./../../../src/templates/dictionary-detail.tsx" /* webpackChunkName: "component---src-templates-dictionary-detail-tsx" */),
  "component---src-templates-dictionary-tsx": () => import("./../../../src/templates/dictionary.tsx" /* webpackChunkName: "component---src-templates-dictionary-tsx" */),
  "component---src-templates-home-tsx": () => import("./../../../src/templates/home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-journal-category-list-tsx": () => import("./../../../src/templates/journal-category-list.tsx" /* webpackChunkName: "component---src-templates-journal-category-list-tsx" */),
  "component---src-templates-journal-detail-tsx": () => import("./../../../src/templates/journal-detail.tsx" /* webpackChunkName: "component---src-templates-journal-detail-tsx" */),
  "component---src-templates-journal-list-tsx": () => import("./../../../src/templates/journal-list.tsx" /* webpackChunkName: "component---src-templates-journal-list-tsx" */),
  "component---src-templates-news-category-list-tsx": () => import("./../../../src/templates/news-category-list.tsx" /* webpackChunkName: "component---src-templates-news-category-list-tsx" */),
  "component---src-templates-news-detail-tsx": () => import("./../../../src/templates/news-detail.tsx" /* webpackChunkName: "component---src-templates-news-detail-tsx" */),
  "component---src-templates-news-list-tsx": () => import("./../../../src/templates/news-list.tsx" /* webpackChunkName: "component---src-templates-news-list-tsx" */),
  "component---src-templates-search-list-tsx": () => import("./../../../src/templates/search-list.tsx" /* webpackChunkName: "component---src-templates-search-list-tsx" */),
  "component---src-templates-store-category-list-tsx": () => import("./../../../src/templates/store-category-list.tsx" /* webpackChunkName: "component---src-templates-store-category-list-tsx" */),
  "component---src-templates-store-detail-tsx": () => import("./../../../src/templates/store-detail.tsx" /* webpackChunkName: "component---src-templates-store-detail-tsx" */),
  "component---src-templates-store-list-tsx": () => import("./../../../src/templates/store-list.tsx" /* webpackChunkName: "component---src-templates-store-list-tsx" */),
  "component---src-templates-store-vendor-list-tsx": () => import("./../../../src/templates/store-vendor-list.tsx" /* webpackChunkName: "component---src-templates-store-vendor-list-tsx" */)
}

