import React from 'react';
import { Helmet } from 'react-helmet';

const DefaultMeta: React.FunctionComponent = () => {
  return (
    <Helmet>
      <title>POST-FAKE</title>
      <meta
        name="Description"
        content="POST-FAKEは新たなアート/カルチャーの世界を築くプラットフォーム。アーティスト、クリエイターの創造のエッセンスに光を照らし、リアルのアートを発信します。"
      />
      <meta property="og:type" content="website" />
      <meta
        property="og:description"
        content="POST-FAKEは新たなアート/カルチャーの世界を築くプラットフォーム。アーティスト、クリエイターの創造のエッセンスに光を照らし、リアルのアートを発信します。"
      />
      <meta property="og:title" content="POST-FAKE" />
      <meta property="og:url" content="https://postfake.com/" />
      <meta property="og:image" content="https://postfake.com/ogp.jpg" />
      <meta property="og:locale" content="ja_JP" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="POST-FAKE" />
      <meta name="twitter:title" content="POST-FAKE" />
      <meta
        name="twitter:description"
        content="POST-FAKEは新たなアート/カルチャーの世界を築くプラットフォーム。アーティスト、クリエイターの創造のエッセンスに光を照らし、リアルのアートを発信します。"
      />

      <link rel="shortcut icon" href="/favicon.ico" />
      <link rel="apple-touch-icon" href="/icon.png"></link>
    </Helmet>
  );
};
export default DefaultMeta;
