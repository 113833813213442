import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import HideLink from './hide-link';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Pagination } from 'swiper';

import { AppContext, IAppContext } from '../../context/context';
import { PATH } from '../../helper/config';
import { OnlyPC, OnlySP } from '../../helper/media-helper';
import Util from '../utils/util';
import LazyImage from '../../components/utils/lazy-image';
import MailingList from '../../components/utils/mailing-list';

SwiperCore.use([Autoplay, Pagination]);

const GlobalNavigation: React.FunctionComponent = () => {
  const context = useContext<Partial<IAppContext>>(AppContext);

  const gnavData = useStaticQuery(graphql`
    query globalNavigationCategoryQuery {
      category: allWpGqlJournalCategoryItem(sort: { fields: name, order: ASC }) {
        edges {
          node {
            slug
            name
          }
        }
      }

      pickup: wp {
        ja: menuConfigGeneral {
          menuPickup {
            optionMenuPickup {
              ... on WpGqlJournalItem {
                databaseId
                date
                gqlJournalCategories {
                  nodes {
                    name
                  }
                }
                optionJournalFields {
                  title
                  thumbnail {
                    mediaItemUrl
                    mediaDetails {
                      height
                      width
                    }
                  }
                }
                contentType {
                  node {
                    name
                  }
                }
              }
              ... on WpGqlNewsItem {
                databaseId
                date
                gqlNewsCategories {
                  nodes {
                    name
                  }
                }
                title
                gqlNewsAcf {
                  image {
                    mediaItemUrl
                    mediaDetails {
                      height
                      width
                    }
                  }
                }
                contentType {
                  node {
                    name
                  }
                }
              }
            }
          }
        }
        en: menuConfigGeneralEn {
          menuPickupEn {
            optionMenuPickupEn {
              ... on WpGqlJournalItem {
                databaseId
                date
                gqlJournalCategories {
                  nodes {
                    name
                  }
                }
                optionJournalFields {
                  title
                  thumbnail {
                    mediaItemUrl
                    mediaDetails {
                      height
                      width
                    }
                  }
                }
                contentType {
                  node {
                    name
                  }
                }
              }
              ... on WpGqlNewsItem {
                databaseId
                date
                gqlNewsCategories {
                  nodes {
                    name
                  }
                }
                title
                gqlNewsAcf {
                  image {
                    mediaItemUrl
                    mediaDetails {
                      height
                      width
                    }
                  }
                }
                contentType {
                  node {
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  return (
    <div className={`global-navigation ${context.isGlobalNavigation ? 'active' : ''}`}>
      <div
        className={`global-navigation__bg ${context.isGlobalNavigation ? 'active' : ''}`}
        onClick={() => {
          context.toggleGlobalNavigation(false);
        }}></div>
      <div className={`global-navigation__wrapper ${context.isGlobalNavigation ? 'active' : ''}`}>
        <nav className={'global-navigation__inner'}>
          <div className="global-navigation__row">
            <OnlySP>
              <Search />
            </OnlySP>
            <div className="global-navigation__column global-navigation__column--left">
              <MainNavigation data={gnavData.category} />
              <FooterElem />
              <SnsNavigation />
            </div>
            <OnlyPC>
              <PickupSlide data={gnavData.pickup} />
            </OnlyPC>
          </div>

          <OnlySP>
            <div className="global-navigation__sidebar">
              {/* <div className="global-navigation__language ff-univ">
                <Link
                  to={context.language.ja}
                  className="link__text"
                  activeClassName="active"
                  partiallyActive={context.language.ja === '/' ? false : true}
                  onClick={() => {
                    context.toggleGlobalNavigation(false);
                  }}>
                  Ja
                </Link>
                <Link
                  to={context.language.en}
                  className="link__text"
                  activeClassName="active"
                  partiallyActive={context.language.ja === '/' ? false : true}
                  onClick={() => {
                    context.toggleGlobalNavigation(false);
                  }}>
                  En
                </Link>
              </div> */}
              <a href={PATH.MY_PAGE} className="ff-univ-bold global-navigation__mypage">
                MYPAGE
              </a>
            </div>
          </OnlySP>
        </nav>
      </div>
    </div>
  );
};
export default React.memo(GlobalNavigation);

/* =================================================================== */
const MainNavigation: React.FunctionComponent<any> = React.memo(({ data }) => {
  return (
    <div className="global-navigation__main-navigation-row">
      <p className="global-navigation__home ff-univ-bold">
        <HideLink to={PATH.ROOT} className="link__global-nav link__global-nav--home">
          <span>H</span>
          <span>OME</span>
        </HideLink>
      </p>

      <ul className="global-navigation__list ff-univ-bold">
        <li className="global-navigation__list-item">
          <HideLink to={PATH.JOURNAL} className="link__global-nav link__global-nav--journal">
            <span>J</span>
            <span>OURNAL</span>
          </HideLink>
          <ul className="global-navigation__sub-list ff-univ">
            <li className="global-navigation__sub-list-item" key={`global-navigation__sub-list-item-all`}>
              <HideLink to={`${PATH.JOURNAL}`} className="link__text">
                ALL
              </HideLink>
            </li>
            {data.edges &&
              data.edges.map((elem: any, i: number) => {
                if (!elem.node.slug.includes('pf-product')) {
                  return (
                    <li className="global-navigation__sub-list-item" key={`global-navigation__sub-list-item-${elem.node.slug}`}>
                      <HideLink to={`${PATH.JOURNAL}category/${elem.node.slug}`} className="link__text">
                        {elem.node.name}
                      </HideLink>
                    </li>
                  );
                }
              })}
            <li className="global-navigation__sub-list-item" key={`global-navigation__sub-list-item-dictionary`}>
              <HideLink to={`${PATH.DICTIONARY}`} className="link__text">
                POST-FAKE DICTIONARY
              </HideLink>
            </li>
          </ul>
        </li>
        <li className="global-navigation__list-item">
          <HideLink to={PATH.ARTISTS} className="link__global-nav">
            <span>A</span>
            <span>RTISTS</span>
          </HideLink>
        </li>
        <li className="global-navigation__list-item">
          <HideLink to={PATH.STORE} className="link__global-nav" target="_blank" rel="noopener">
            <span>S</span>
            <span>TORE</span>
          </HideLink>
        </li>
        <li className="global-navigation__list-item">
          <HideLink to={PATH.NEWS} className="link__global-nav">
            <span>N</span>
            <span>EWS</span>
          </HideLink>
        </li>
        <li className="global-navigation__list-item">
          <HideLink to={PATH.ABOUT} className="link__global-nav">
            <span>A</span>
            <span>BOUT</span>
          </HideLink>
        </li>
      </ul>
    </div>
  );
});

/* =================================================================== */
const SnsNavigation: React.FunctionComponent = React.memo(() => {
  return (
    <ul className="global-navigation__sns-navigation-list">
      <li className="global-navigation__sns-navigation-list-item">
        <a href={PATH.INSTAGRAM} className="link__alpha" target="_blank" rel="noopener">
          <i className="glyphs-instagram"></i>
        </a>
      </li>
      <li className="global-navigation__sns-navigation-list-item">
        <a href={PATH.TWITTER} className="link__alpha" target="_blank" rel="noopener">
          <i className="glyphs-twitter"></i>
        </a>
      </li>
      <li className="global-navigation__sns-navigation-list-item">
        <a href={PATH.YOUTUBE} className="link__alpha" target="_blank" rel="noopener">
          <i className="glyphs-youtube"></i>
        </a>
      </li>
    </ul>
  );
});

/* =================================================================== */
const FooterElem: React.FunctionComponent = React.memo(() => {
  return (
    <div className="global-navigation__footer-navigation-row">
      <div className="global-navigation__sub-column">
        <MailingList />
      </div>

      <ul className="global-navigation__navigation-list ff-univ-bold">
        <li className="global-navigation__navigation-list-item">
          <a className="link__text" href={PATH.GUIDE}>
            SHOP GUIDE
          </a>
        </li>
        {/* <li className="global-navigation__navigation-list-item">
          <a className="link__text" href={PATH.FAQ}>
            FAQ
          </a>
        </li> */}
        <li className="global-navigation__navigation-list-item">
          <a className="link__text" href={PATH.CONTACT}>
            CONTACT
          </a>
        </li>
      </ul>

      <ul className="global-navigation__sub-navigation-list ff-univ">
        {/* <li className="global-navigation__sub-navigation-list-item">
          <a className="link__text" href={PATH.TERMS}>
            TERMS AND CONDITIONS
          </a>
        </li> */}
        <li className="global-navigation__sub-navigation-list-item">
          <a className="link__text" href={PATH.PRIVACY}>
            PRIVACY POLICY
          </a>
        </li>
        <li className="global-navigation__sub-navigation-list-item">
          <a className="link__text" href={PATH.LEGAL}>
            LEGAL INFORMATION
          </a>
        </li>
      </ul>
    </div>
  );
});

/* =================================================================== */

const PickupSlide: React.FunctionComponent<any> = React.memo(({ data }) => {
  const context = useContext(AppContext);
  const [targetData, setTargetData] = useState(null);

  useEffect(() => {
    if (context.locale === 'en_US') setTargetData(data.en.menuPickupEn.optionMenuPickupEn);
    else setTargetData(data.ja.menuPickup.optionMenuPickup);
  }, [context.locale]);

  if (!targetData) return <></>;

  return (
    <div className="global-navigation__column global-navigation__column--right">
      <div className="global-navigation__slide">
        <div className="global-navigation__slide-navigation swiper-pagination ff-mono"></div>
        <Swiper
          spaceBetween={0}
          slidesPerView={1}
          pagination={{
            el: '.global-navigation__slide-navigation',
            clickable: true,
            renderBullet: (index, className) => {
              return '<span class="' + className + ' link__alpha">' + (index + 1) + '</span>';
            }
          }}
          className="global-navigation__pickup-wrap">
          {targetData.map((elem: any, i: number) => {
            if (elem.optionJournalFields) {
              return (
                <SwiperSlide key={`gnavPickupSlide-${i}-${elem.databaseId}`}>
                  <HideLink to={`${PATH.JOURNAL_DETAIL}${elem.databaseId}`} className="global-navigation__pickup ">
                    <figure className="global-navigation__pickup-figure">
                      {elem.optionJournalFields.thumbnail && <LazyImage className="fit" data={elem.optionJournalFields.thumbnail} />}
                    </figure>
                    <div className="global-navigation__pickup-column">
                      <p className="global-navigation__pickup-date">{Util.GET_DATE(elem.date).ja}</p>
                      <p className="global-navigation__pickup-title ff-univ">{elem.optionJournalFields.title}</p>
                      {elem.gqlJournalCategories && elem.gqlJournalCategories.nodes && elem.gqlJournalCategories.nodes.length && (
                        <p className="global-navigation__pickup-category">#{elem.gqlJournalCategories.nodes[0].name}</p>
                      )}
                    </div>
                  </HideLink>
                </SwiperSlide>
              );
            } else {
              return (
                <SwiperSlide key={`gnavPickupSlide-${i}-${elem.databaseId}`}>
                  <HideLink to={`${PATH.NEWS_DETAIL}${elem.databaseId}`} className="global-navigation__pickup ">
                    <figure className="global-navigation__pickup-figure">
                      {elem.gqlNewsAcf.image && <LazyImage className="fit" data={elem.gqlNewsAcf.image} />}
                    </figure>
                    <div className="global-navigation__pickup-column">
                      <p className="global-navigation__pickup-date">{Util.GET_DATE(elem.date).ja}</p>
                      <p className="global-navigation__pickup-title ff-univ">{elem.title}</p>
                      {elem.gqlNewsCategories && elem.gqlNewsCategories.nodes && elem.gqlNewsCategories.nodes.length && (
                        <p className="global-navigation__pickup-category">#{elem.gqlNewsCategories.nodes[0].name}</p>
                      )}
                    </div>
                  </HideLink>
                </SwiperSlide>
              );
            }
          })}
        </Swiper>
      </div>
    </div>
  );
});

/* =================================================================== */
const Search: React.FunctionComponent = () => {
  const ref: React.RefObject<any> = useRef(null);
  return (
    <div className="global-navigation__search">
      <form action="">
        <input
          type="search"
          placeholder="SEARCH"
          className="ff-univ"
          ref={ref}
          onKeyDown={(event) => {
            if (event.keyCode === 13) {
              if (typeof window !== 'undefined') {
                location.href = `${PATH.SEARCH_LIST}${ref.current.value}`;
              }
            }
          }}
        />
      </form>
      <i
        className="glyphs-search"
        onPointerDown={() => {
          if (typeof window !== 'undefined') {
            location.href = `${PATH.SEARCH_LIST}${ref.current.value}`;
          }
        }}></i>
    </div>
  );
};
