import React, { useContext } from 'react';
import { Link } from 'gatsby';
import { AppContext } from '../../context/context';

const HideLink: React.FunctionComponent<any> = (props) => {
  const context = useContext(AppContext);
  return (
    <Link
      {...props}
      to={`${context.localePath}${props.to}`}
      onClick={() => {
        context.toggleGlobalNavigation(false);
      }}
      activeClassName="active">
      {props.children}
    </Link>
  );
};
export default HideLink;
