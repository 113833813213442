import React, { useContext, useEffect } from 'react';

import VhController from './utils/vh-controller';
import { AppContext, ILanguage, ThemeColor } from '../context/context';
import { OnlyPC } from '../helper/media-helper';

import Header from './components/header';
import Footer from './components/footer';
import GlobalNavigation from './components/global-navigation';
import SearchWindow from './components/search-window';

import '../css/common.css';
import MovieModal from './components/movie-modal';
import Cart from '../components/utils/cart';
import { BreakpointProvider } from 'gatsby-plugin-breakpoints';
import ModalCloser from '../components/utils/modal-closer';
import ShopifyHelper from '../components/journal/detail/units/shopify-helper';
import DefaultMeta from '../components/utils/default-meta';
new VhController();

const Provider: React.Provider<any> = AppContext.Provider;

if (typeof window !== 'undefined') {
  require('smoothscroll-for-websites');
}

class Base extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      localePath: '',
      setLocalePath: this.setLocalePath,
      isGlobalNavigation: false,
      toggleGlobalNavigation: this.toggleGlobalNavigation,
      isSearchWindow: false,
      toggleSearchWindow: this.toggleSearchWindow,
      isCartWindow: false,
      toggleCartWindow: this.toggleCartWindow,
      movieId: '',
      isMovieModal: false,
      toggleMovieModal: this.toggleMovieModal,
      theme: ThemeColor.DEFAULT,
      setTheme: this.setTheme,
      cartId: '',
      setCartID: this.setCartID,
      cartData: {},
      setCartData: this.setCartData,
      locale: null,
      language: { ja: '/', en: '/en/' },
      setLanguage: this.setLanguage
    };
  }

  private setLanguage = (language: ILanguage, locale: string): void => {
    this.setState({ language, locale });
  };

  private setCartData = (cartData: any): void => {
    this.setState({ cartData });
  };

  private setLocalePath = (localePath: string): void => {
    this.setState({ localePath });
  };

  private toggleMovieModal = (movieId: string, isMovieModal: boolean): void => {
    this.setState({ movieId, isMovieModal });
  };

  private toggleGlobalNavigation = (isGlobalNavigation: boolean): void => {
    this.setState({ isGlobalNavigation });
  };

  private toggleSearchWindow = (isSearchWindow: boolean): void => {
    this.setState({ isSearchWindow });
  };

  private toggleCartWindow = (isCartWindow: boolean): void => {
    this.setState({ isCartWindow });
  };

  private setTheme = (theme: number): void => {
    this.setState({ theme });
  };
  private setCartID = (cartId: string): void => {
    this.setState({ cartId });
  };

  /**
   * React.render
   * @private
   * @returns { React.Component }
   */
  public render() {
    let colorClass = '';
    if (this.state.theme === ThemeColor.BROWN) colorClass = 'brown';
    if (this.state.theme === ThemeColor.BLACK) colorClass = 'black';
    if (this.state.theme === ThemeColor.WHITE) colorClass = 'white';
    if (this.state.theme === ThemeColor.GRAY) colorClass = 'gray';
    if (this.state.theme === ThemeColor.DARK_GRAY) colorClass = 'dark_gray';

    if (typeof document !== 'undefined' && document.body) {
      document.body.classList.remove('brown');
      document.body.classList.remove('black');
      document.body.classList.remove('white');
      document.body.classList.remove('gray');
      document.body.classList.remove('dark_gray');
      if (colorClass) document.body.classList.add(colorClass);
    }

    return (
      <div>
        <Provider value={this.state}>
          <BreakpointProvider
            queries={{
              mobile: '(max-width: 414px)',
              pc: '(min-width: 415px)'
            }}>
            <div className="root">
              <DefaultMeta />
              <ShopifyHelper />
              <GnWatcher />
              <GlobalNavigation />
              <Header />
              <main>{this.props.children}</main>
              <Footer />

              {this.state.isSearchWindow && (
                <OnlyPC>
                  <ModalCloser callback={this.state.toggleSearchWindow} />
                  <SearchWindow />
                </OnlyPC>
              )}
              {this.state.isCartWindow && (
                <>
                  <ModalCloser callback={this.state.toggleCartWindow} />
                  <Cart />
                </>
              )}
              {this.state.isMovieModal && <MovieModal />}
            </div>
          </BreakpointProvider>
        </Provider>
      </div>
    );
  }
}
export default Base;

const GnWatcher: React.FunctionComponent = () => {
  const context = useContext(AppContext);

  if (typeof location !== 'undefined') {
    useEffect(() => {
      context.toggleGlobalNavigation(false);
    }, [location.href]);

    useEffect(() => {
      context.setLocalePath(location.pathname.includes('/en') ? '/en' : '');
    }, [location.pathname]);
  }
  return <></>;
};
