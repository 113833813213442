import React, { useContext, useEffect, useRef, useState } from 'react';
import YouTube from 'react-youtube';
import { AppContext, IAppContext } from '../../context/context';
import { useMediaQuery } from 'react-responsive';

enum ModalState {
  DEFAULT,
  SHOW,
  HIDE
}

const MovieModal: React.FunctionComponent = () => {
  const refNode: React.RefObject<any> = useRef(null);

  const context = useContext<Partial<IAppContext>>(AppContext);
  const [modalState, setModalState] = useState(ModalState.DEFAULT);
  const isSP: boolean = useMediaQuery({ query: `(max-width: 414px)` });

  useEffect(() => {
    setModalState(ModalState.SHOW);
  }, []);

  const opts = {
    height: '390',
    width: '640',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
      controls: 1,
      loop: 0,
      rel: 0,
      playsinline: 1,
      mute: 0
    }
  };

  return (
    <div className={`movie-modal ${modalState === ModalState.SHOW ? 'show' : ''} ${modalState === ModalState.HIDE ? 'hide' : ''}`}>
      <p
        className="movie-modal__bg"
        onClick={() => {
          context.toggleMovieModal('', false);
        }}></p>
      <div className="movie-modal__window">
        <YouTube videoId={context.movieId} opts={opts} />
      </div>
      <p
        className="movie-modal__close ff-univ-bold link__text"
        onClick={() => {
          context.toggleMovieModal('', false);
        }}>
        CLOSE
      </p>
    </div>
  );
};
export default MovieModal;
