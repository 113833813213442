import React, { useContext, useEffect, useState } from 'react';
import { GraphQLClient, gql } from 'graphql-request';
import { AppContext } from '../../../../context/context';
import API from '../../../../templates/utils/api';

const ShopifyHelper: React.FunctionComponent = () => {
  const context = useContext(AppContext);
  const [myLocale, setLocale] = useState(null);
  const endpoint = API.STORE_FRONT;
  const graphQLClient = new GraphQLClient(endpoint, {
    headers: {
      'Content-Type': 'application/json',
      'X-Shopify-Storefront-Access-Token': API.STORE_FRONT_TOKEN,
      'Accept-Language': !context.localePath.length ? 'ja-jp' : 'en-US'
    }
  });

  useEffect(() => {
    if (!context.cartId.length) {
      createCart();
    }
  }, []);

  useEffect(() => {
    if (!context.cartId.length) return;
    getCartItem();
  }, [context.cartId]);

  useEffect(() => {
    if (!context.locale) return;

    if (myLocale) {
      if (myLocale !== context.locale) {
        localStorage.removeItem('cart_id');
        createCart();
      }
    }

    setLocale(context.locale);
  }, [context.locale]);

  const getCartItem = async () => {
    if (context.cartData.id) return;
    const queryJa = gql`
      query getCart($cartId: ID!) @inContext(country: JP) {
        cart(id: $cartId) {
          id
          lines(first: 50) {
            edges {
              node {
                id
                quantity
                merchandise {
                  ... on ProductVariant {
                    id
                    image {
                      height
                      width
                      transformedSrc
                    }
                    priceV2 {
                      amount
                      currencyCode
                    }
                    quantityAvailable
                    title
                    product {
                      handle
                      title
                    }
                  }
                }
              }
            }
          }
        }
      }
    `;
    const queryEn = gql`
      query getCart($cartId: ID!) @inContext(country: US) {
        cart(id: $cartId) {
          id
          lines(first: 50) {
            edges {
              node {
                id
                quantity
                merchandise {
                  ... on ProductVariant {
                    id
                    image {
                      height
                      width
                      transformedSrc
                    }
                    priceV2 {
                      amount
                      currencyCode
                    }
                    quantityAvailable
                    title
                    product {
                      handle
                      title
                    }
                  }
                }
              }
            }
          }
        }
      }
    `;

    const variables = {
      cartId: context.cartId
    };
    const data = await graphQLClient.request(!context.localePath.length ? queryJa : queryEn, variables);
    context.setCartData({ ...data.cart });
  };

  const createCart = async (): Promise<any> => {
    const cartID = localStorage.getItem('cart_id');
    if (cartID) {
      context.setCartID(cartID);
      return;
    }

    const queryJa = gql`
      mutation createCart($cartInput: CartInput) @inContext(country: JP) {
        cartCreate(input: $cartInput) {
          cart {
            id
            lines(first: 50) {
              edges {
                node {
                  id
                  quantity
                  merchandise {
                    ... on ProductVariant {
                      id
                      image {
                        height
                        width
                        transformedSrc
                      }
                      priceV2 {
                        amount
                        currencyCode
                      }
                      quantityAvailable
                      title
                      product {
                        handle
                        title
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `;
    const queryEn = gql`
      mutation createCart($cartInput: CartInput) @inContext(country: US) {
        cartCreate(input: $cartInput) {
          cart {
            id
            lines(first: 50) {
              edges {
                node {
                  id
                  quantity
                  merchandise {
                    ... on ProductVariant {
                      id
                      image {
                        height
                        width
                        transformedSrc
                      }
                      priceV2 {
                        amount
                        currencyCode
                      }
                      quantityAvailable
                      title
                      product {
                        handle
                        title
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `;

    const data = await graphQLClient.request(!context.localePath.length ? queryJa : queryEn);
    localStorage.setItem('cart_id', data.cartCreate.cart.id);
    context.setCartID(data.cartCreate.cart.id);
    context.setCartData({ ...data.cartCreate.cart });
  };

  return null;
};
export default ShopifyHelper;
