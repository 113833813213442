import React from 'react';
import { PATH } from '../../helper/config';

const MailingList: React.FunctionComponent = () => {
  return (
    <a className="mailing__list" href={PATH.MAILING_LIST}>
      <div className="mailing__list-column-left">
        <video src="/mailing.mp4" playsInline muted autoPlay loop></video>
      </div>
      <div className="mailing__list-column">
        <div className="mailing__list-text mailing__list-text--primary ff-univ-bold">
          JOIN OUR
          <br />
          MAILING LIST
        </div>
        <div className="mailing__list-text mailing__list-text--secondary ff-univ">POST-FAKE NEWSLETTER</div>
      </div>
    </a>
  );
};
export default MailingList;
