module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[],"mergeSecurityHeaders":false,"allPageHeaders":["X-Frame-Options: ALLOW-FROM https://store.postfake.com/"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"G-0BV5JFHY91","includeInDevelopment":true,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-breakpoints/gatsby-browser.js'),
      options: {"plugins":[],"queries":{"mobile":"(max-width: 414px)","pc":"(min-width: 415px)"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"http://postfake.conohawing.com/graphql","verbose":true,"hostingWPCOM":false,"protocol":"https","useACF":true,"schema":{"perPage":20,"requestConcurrency":2,"previewRequestConcurrency":2},"production":{"hardCacheMediaFiles":false,"allow404Images":true},"develop":{"hardCacheMediaFiles":false,"allow404Images":true},"type":{"MediaItem":{"lazyNodes":true,"localFile":{"excludeByMimeTypes":["video/mp4","image/jpeg","image/webp","image/gif","image/png"],"requestConcurrency":50,"maxFileSizeBytes":1}}}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
