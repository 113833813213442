import React, { createRef, useContext, useEffect, useRef, useState } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { PATH } from '../../helper/config';
import { OnlyPC, OnlySP } from '../../helper/media-helper';
import { AppContext, ThemeColor } from '../../context/context';
import { POST } from '../utils/util';

import MailingList from '../../components/utils/mailing-list';

const Footer: React.FunctionComponent = () => {
  const ref = useRef();
  const context = useContext(AppContext);
  const data = useStaticQuery(
    graphql`
      query {
        pageTop: file(relativePath: { eq: "utils/page_top.svg" }) {
          publicURL
        }
        pageTopWhite: file(relativePath: { eq: "utils/page_top_white.svg" }) {
          publicURL
        }
      }
    `
  );

  return (
    <>
      <footer className="footer">
        <div className="footer__row">
          <div className="footer__column footer__column--left">
            <MailingList />
            <OnlyPC>
              <p className="footer__copyright ff-univ">©POST-FAKE</p>
            </OnlyPC>
          </div>
          <nav className="footer__column footer__column--right ff-univ-bold">
            <ul className="footer__navigation-list">
              <li className="footer__navigation-list-item">
                <a className="link__text" href={PATH.GUIDE}>
                  SHOP GUIDE
                </a>
              </li>
              {/* <li className="footer__navigation-list-item">
              <a className="link__text" href={PATH.FAQ}>
                FAQ
              </a>
            </li> */}
              <li className="footer__navigation-list-item">
                <a className="link__text" href={PATH.CONTACT}>
                  CONTACT
                </a>
              </li>
            </ul>

            <ul className="footer__sub-navigation-list ff-univ">
              {/* <li className="footer__sub-navigation-list-item">
              <a className="link__text" href={PATH.TERMS}>
                TERMS AND CONDITIONS
              </a>
            </li> */}
              <li className="footer__sub-navigation-list-item">
                <a className="link__text" href={PATH.PRIVACY}>
                  PRIVACY POLICY
                </a>
              </li>
              <li className="footer__sub-navigation-list-item">
                <a className="link__text" href={PATH.LEGAL}>
                  LEGAL INFORMATION
                </a>
              </li>
            </ul>

            <ul className="footer__sns-navigation-list ff-univ">
              <li className="footer__sns-navigation-list-item">
                <a href={PATH.INSTAGRAM} className="link__text" target="_blank" rel="noopener">
                  <span>
                    {((): any => {
                      if (context.theme === ThemeColor.BLACK || context.theme === ThemeColor.DARK_GRAY) {
                        return (
                          <img
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGsAAABYCAMAAADFj/k9AAAANlBMVEUAAAD////////////////////////////////////////////////////////////////////xY8b8AAAAEXRSTlMAwECAEPAgoODQkFAwsGBwX9haDiQAAAEBaVRYdFhNTDpjb20uYWRvYmUueG1wAAAAAAA8P3hwYWNrZXQgYmVnaW49Iu+7vyI/PjxyOlJERiB4bWxuczpyPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj48cjpEZXNjcmlwdGlvbiB4bWxuczp4PSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIj48eDpDcmVhdGVEYXRlPjIwMjEtMDktMjhUMTQ6Mzc6MTIrMDk6MDA8L3g6Q3JlYXRlRGF0ZT48L3I6RGVzY3JpcHRpb24+PC9yOlJERj48P3hwYWNrZXQgZW5kPSJyIj8+JIYGCgAAAzdJREFUaN7VmdmS4yAMRQ1iN3j5/5+dStXYSsIgWVYy1a23dhOOtQDXYqLNzgDmYTtl/jEiAtgw3bRaBgSK2jYnBjlI+01rs4xUdo0lex21+F1pxl10Ku568/MlVN4/YosCpYTxqNRgsZZPdbDWQjEyWHsGreK1Ob/kupJjVxyYOW/45ZIcFQosdpjuWsU0RGLYGQFPxprPOR/F0KVVC4u8W6t8egswh/OvczMNo/HnDiNGwd9t9ygGe0xVRtsgRllmzpx5Pn56PEn02pK71foyt8x7HwU/SxcwojBoiUx9PYZLOBivt18fS7pRrycPYXph1dcgZqKW2B3DbgCtPAuZ/cXs21Miw2S63PocrxQtwUpUcZhz8MBC7JXFNsoXTkexnEgapPkI/lu2G8XyZBnOfqjRns9XX7n088kslETb8D3WSc2KtGjaMqonCSsJUBi5tZgcwU0ylmFQPsJqZyj55WHoE3yLtTyXGUYpgMfnuV/zBMuNWMGP9I4DhJWORRzNdsRqhFSx+B6VmI9j4XNKGdSEi1jPMrROqadnVs2qmH3mpIxqVuFECDrutKyEb82pykXHwokCL22ikrVQ2qsbo2NBt1QJDatk4elKWMby+SarH6RnBYrVPsuql/z6PTHsRCMlfMPXax7X+39Yy2u3Oyv3KMunq6j33v7x6DCdtSxAxxi3/KRkYRCzI7KlOyt7cWjoCO5Bz6pkx8d6/K+ChSpgHMalV748i9CizuOE22suzX7aSmhRgca2XU+x7xU2TmPzLIwUWm4AEM3h7ahI5299E2Gy9N96hUTlMOlZaIu08Y8s+bd5TSMUUG0+LFCRtAD/T6cCJ0IolhXcHTUcLJouXuj1ziVj8bXVSYVBn0z+rg9W28/Bl5q+p0fOlmmJ4j7BKvS3VFI056WTRT6I8h5wYP5v9SyD6aIb2VmNWtgbDMAmjMqw1ePdT7i7mQrClKj+XCOkRXR3Ufbihd383Oy8Ratt0OijD2BfrLQm1kwoA05amAhgH1aJa7aHbVBMemvM/qC7bPRMab4KLgR1llkU6hilFUENrypaC8Lds90EpRImsbklir0zQOeJFzLNsFYuyJ0/lgvUUgpHxHoAAAAASUVORK5CYII="
                            alt=""
                          />
                        );
                      } else {
                        return (
                          <img
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGsAAABYCAMAAADFj/k9AAAAQlBMVEUAAAAhISEgICAiIiIgICAhISEgICAhISEiIiIfHx8gICAhISEgICAhISEhISEhISEhISEgICAiIiIiIiIgICAiIiJuT8LkAAAAFXRSTlMAwECAEPAg0JBQMKBgcN/gsD+fr1/43DeLAAABAWlUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iPz48cjpSREYgeG1sbnM6cj0iaHR0cDovL3d3dy53My5vcmcvMTk5OS8wMi8yMi1yZGYtc3ludGF4LW5zIyI+PHI6RGVzY3JpcHRpb24geG1sbnM6eD0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wLyI+PHg6Q3JlYXRlRGF0ZT4yMDIxLTA5LTI4VDE0OjM3OjEyKzA5OjAwPC94OkNyZWF0ZURhdGU+PC9yOkRlc2NyaXB0aW9uPjwvcjpSREY+PD94cGFja2V0IGVuZD0iciI/PiSGBgoAAANQSURBVGje1ZnZcusgDIYDYvcaL+//qmcyc2zZIQjLSmda3dUlfNYC/MgP2p4RQL1spcy+RkwAT/e4aSkUCBR1XgwbZKBfb9oceaSwSqzX11GtXYWmzEWnplVuNl5C+fUr1kpQchiNGkZota6n2mmtISgebD6COvbajM0RlsixHQ706M3d5dIbKhRY7PC4awnT0BDD9ghYKtaMnKeyW1lapbCm7lbHn/4JEN3+17DN5Erj9x2GjYL/2+5WDHqbKpS2QYwyz4za87z9dHvSF34y3nVrzMtcV957K/jIREVEYdAGMvVpG87hYLzefr0t6ZF6PX4IhxMrnYPoiVqq7hjPBWAORyGznky/PSUyTKbLdMd49ZMmWANVHGofXDA35cpiKeULp6NYhiUN+rgF/y3bI8WyZBlGW9Rox/PVplr668kMlERb7Io7qZhFSyu7eFRPHNbAQGHkuqB8A+bBY6kKyjbQ6QjBnx66PMG3WO2xzDBKDjBNH7YHTbBMieVsSe8YQFjIWMTRrEusmZAqGt8jleZjsPRKKYM04CKWsxStU9LumRazEma/clI2YlbI81Fy3EhZPb51TVW2MhZO5OrSphGy2uLOlY/phSzIliqhYYUsPF0J81g+P8nKB8lZjtS932WlS379nRgSojFf7+7na94Jap5Yp2TzwH9rj9L1dAXp3jvkj0uHaZSyAB2ruGWl5xcG0RsiW7KzMm9EKDqCq5Oz0j7Z9OlXFt0SsFAFlMPY5sq3ziK0qLGHS8I5l4dbXkdoUYbG1itajz3FeJT5I6WxaVYeKTQ/A8Ck7OmZuXV36ImmWGZ5suR3vUCiPKIELAwjt/GPLP7dPA0lFFBtPixQlrQA+9Epd1GE8I57A5lvIw5mTddc6PXG4LH4xs5whUGeTNp0BIBO53PUS03e0yNn87REMd9gBfouNWDC5NbTkzXyIOY3W1f5v5azFKaLbmR7MaqtfsEAbMKIDFs91vyGbzePgDAhKj/XCGkxmbsoffGDXTw2O2/R0lxo9NEHsA1Pbk10nlQGtLRQE8DzZYn4zPayBYLq3xqzv+hbNnomNJsYHwRl5qso1DFCC4wa7kS00TF3z/EmaAjuxlbTNmzvFNB5qguZUVUtXJA7/wBongSjb5AZkwAAAABJRU5ErkJggg=="
                            alt=""
                          />
                        );
                      }
                    })()}
                  </span>
                  INSTAGRAM
                </a>
              </li>
              <li className="footer__sns-navigation-list-item">
                <a href={PATH.TWITTER} className="link__text" target="_blank" rel="noopener">
                  <span>
                    {((): any => {
                      if (context.theme === ThemeColor.BLACK || context.theme === ThemeColor.DARK_GRAY) {
                        return (
                          <img
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGsAAABYCAMAAADFj/k9AAAANlBMVEUAAAD////////////////////////////////////////////////////////////////////xY8b8AAAAEXRSTlMAEPDAQIDgoGAwIFCwb8/QkH7XHZwAAAEBaVRYdFhNTDpjb20uYWRvYmUueG1wAAAAAAA8P3hwYWNrZXQgYmVnaW49Iu+7vyI/PjxyOlJERiB4bWxuczpyPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj48cjpEZXNjcmlwdGlvbiB4bWxuczp4PSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIj48eDpDcmVhdGVEYXRlPjIwMjEtMDktMjhUMTQ6Mzc6MTIrMDk6MDA8L3g6Q3JlYXRlRGF0ZT48L3I6RGVzY3JpcHRpb24+PC9yOlJERj48P3hwYWNrZXQgZW5kPSJyIj8+JIYGCgAAAi9JREFUaN7tmQuu3CAMRS/mD4EJ+99spXSeaMXjjTN8pFZzNnCwMTeWgg8f/he8ijopJbAY4SyVJ1l7rEPoS1SRCn+jI+aQqDTI8GfRhkTvmPZWUbZ8B31VEk4q5UQHXW7IfC4dHACf8iUO6GCuc/AQpnQ5jq/mJnSI16GYqlwYSPQ4C19mOaqHQI/rqLw2xhsqhRZRnjAGxHBUAYDXJqFF1bt9FTeOoToh4mlKOfCjq+QXYcMYDCOpNrLjqsPaxxc2FNB1sfqo+SrPOi31h1/eVbU0L1Hhe4iv4g+yDd1DsYb+XhbY8PZoWMEOg34nFa8qVPj3YFJoAoaZu3dnuV0lZrgElT7m0Erw07DI0ZzL0uqk8riL/0qnuISZ5rJ4hadZLo0f0Ne79WaHy5WSk4J4zHEpNDRxYCbNh2d/AcdhR9Q4D8bOt2nk3UyXw74mBjBzfhzCCwRtTKg4zRXxkmN1CyvTAspinyxim8ygsnryNbio4aQXwC6bxS2Eo5HPJBMtD21l3lKWWhe7LWb8He8qjAIqi0Mx4Q6BhldrPn59BytufQcrkdZ2sPL2Wm8EGhbFPXm8SdA0vBPyEfF87FBVodymClyVxiiJ2Ik7iMq77oq/CZAa/NmUC5eHHxp2S4XNIfAmPp7yVljEu4Uo+ZsagwuLCsdbYRsXj1011UlfbDMJQ4STuPcUMYxwB6MkHeb9MzZ9Dx0uYCrBnZIajTyTwhqEUkk/iUoJfPjwz/MLkv4Qt+ywIiYAAAAASUVORK5CYII="
                            alt=""
                          />
                        );
                      } else {
                        return (
                          <img
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGsAAABYCAMAAADFj/k9AAAAQlBMVEUAAAAgICAhISEiIiIgICAiIiIhISEgICAgICAgICAgICAhISEhISEhISEhISEhISEiIiIhISEgICAiIiIgICAiIiJktB4+AAAAFXRSTlMAEMCAQPCgYDAgULDP4NDvkN9vcD9K+GXyAAACO0lEQVRo3u2Z6c6bMBBF73i3gbAkfv9XrUQTuRU1GeJF6qecv4l0PMNwPRL48uWn4B5Bbo8HoTFkzRyfjNKhHSR3UUIo/I0MqMOWTMnm/yx61ZQ7prlUlIn/Yn5V4pc5xgUZZLwgc2PMYAG4bf9Ze2RY93PwoDVmGYZXc+/IEPZDMVVjZCCQY4l8meGoboQc+1F5bQwXVApHKD5hDMjKUXkATq53HFHp2RLOsQzVAgrLGuOAU1ccHU5hDMYq5tTIrGtnwwkustEeWRerj5KvcqzTzhY5xKeqRIynoZ2Yi1WYDn82Pnso7tDzs+Bo44+GIXYY5DupeFXhFJ15VTZ/CBhm7l6d5eMqUcNF+iwIBvlalSaWqzTnRmHk9hjLXfy3tIqLpmoug3c4XcslcYLc31s39XDZGMftAbrVcSmcoF6XXB2XY9+A5bAjqpwbY+frNPK2psuiXxM9mDlfjsYbSHdMqFDNFfCWoXULE9UCyqCfLKCbbAIT2e4+OaKKk56AXjaDS5CdS65JJlIM0oixS1mqXewemcrf416FaY9E41C84wpeF6/WfFz7DiZs+w4mgm7bwcTHa/1EONAo7rXDh3ipi3dCPhSWWw9VEopuKs9VyRqfFngYFKLGXs+KvwlohRLIjpHLzRUNu5kjm4HwIS4s4lJYhKuFKPGbNSaaFeWHj8I2NB67ZLKNhzyZ7ijCL9xsHwKKITswSpK+3jfjKe/Rg/WoireL0AeNWO4KbSCl7vJJUIrw5ct/zy+g8k/ou1xwSgAAAABJRU5ErkJggg=="
                            alt=""
                          />
                        );
                      }
                    })()}
                  </span>
                  TWITTER
                </a>
              </li>
              <li className="footer__sns-navigation-list-item">
                <a href={PATH.YOUTUBE} className="link__text" target="_blank" rel="noopener">
                  <span>
                    {((): any => {
                      if (context.theme === ThemeColor.BLACK || context.theme === ThemeColor.DARK_GRAY) {
                        return (
                          <img
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGsAAABYCAMAAADFj/k9AAAAeFBMVEUAAAD////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////GqOSsAAAAJ3RSTlMAaEHA3hCT94CE68vx1CmmWRu5UUoKtHxuOpp3Xi4jBOXFrY1yMp+MuRnwAAABAWlUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iPz48cjpSREYgeG1sbnM6cj0iaHR0cDovL3d3dy53My5vcmcvMTk5OS8wMi8yMi1yZGYtc3ludGF4LW5zIyI+PHI6RGVzY3JpcHRpb24geG1sbnM6eD0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wLyI+PHg6Q3JlYXRlRGF0ZT4yMDIxLTA5LTI4VDE0OjM3OjEyKzA5OjAwPC94OkNyZWF0ZURhdGU+PC9yOkRlc2NyaXB0aW9uPjwvcjpSREY+PD94cGFja2V0IGVuZD0iciI/PiSGBgoAAAIxSURBVGje7dnbjpswFIXhBdgQjgESEsj5OOv937CO0ijtzKjFm2C1Ur5rpF9IyHsj4+3t36V33XzTTox4dQqMVGX8XpIHRj0NY/P00iv9bYU+/M06nAZ5woEilS4+4nZW4HvaW0R8NbWa44smjjgO9blWKI4nxK+2Ccc0xdNOkBLG9jnH5uGnNUcX6XtKZxzf+d7y6EC2x82CLsxxk9GFEMaWTqQwNnQignGmGwWAmm7MACi6cQH2dCQEKjpyAjo6kgIz9pVxEGVzGnqbA4cAJv1baJYR5RqEVvOu+qBYhanlbL0GFCqwsJ7jM0WRDkfblnHJZBMsF7SgQ9GBqAQto6hpq0Qiahm+oh0PkbBltAlttKC8BW31w7EWtZ52U/a2FLaeupQ9xeLW0+bgroVmErlqGdc+sfAlrX6jZvWCVqlIOnmvbcCezogGtbTF7IyRDGjt1xn7W+IgaMmG5gVK2ioC63M+l7X0SjC/UklrL9kCZggErbmigC/Yo4qaIlfr/VCHFNpZ7r37NqGURmzT8hXlgLZ/a7fgAAlQsq+Ug+SAT0dqoKAjK6ChI0sACd0oARzpxhXAB91oAKzphIIxpxMLGJpOnAFnH8cGNxe6oHFT0YET7kKOb4s7feDYznjwObK0AR5KjirXgKNYqvGbIuVYYnwxP2V8vTyu8K1uuarzhC8RqeMp9Cr8hS66mbeeGOG0PgbB4978T6LD4/p80pb+tWowTLX1P+l2Gm9v/5cf75wx+mYTbagAAAAASUVORK5CYII="
                            alt=""
                          />
                        );
                      } else {
                        return (
                          <img
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGsAAABYCAMAAADFj/k9AAAAn1BMVEUAAAAgICAhISEgICAhISEhISEiIiIeHh4iIiIhISEhISEfHx8hISEhISEhISEhISEhISEgICAZGRkhISEhISEgICAgICAhISEgICAhISEhISEfHx8fHx8ZGRkAAAAhISEhISEhISEhISEhISEhISEhISEgICAeHh4hISEhISEgICAhISEfHx8fHx8hISEhISEhISEhISEiIiIhISEiIiKGIGIVAAAANHRSTlMAaMBA9t+AD/HUkimm6sq5g1EKtHxuOtp3XkQuIxME5cWtlI1yWzIZn4dWTUge7c2cmIH5gbYHPgAAAQFpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/Ij8+PHI6UkRGIHhtbG5zOnI9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiPjxyOkRlc2NyaXB0aW9uIHhtbG5zOng9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iPjx4OkNyZWF0ZURhdGU+MjAyMS0wOS0yOFQxNDozNzoxMiswOTowMDwveDpDcmVhdGVEYXRlPjwvcjpEZXNjcmlwdGlvbj48L3I6UkRGPjw/eHBhY2tldCBlbmQ9InIiPz4khgYKAAACSElEQVRo3u3Z2W7iQBCF4WPHxhteAbPvAUIIWc/7P9u0iDJLgmbcbVyaSHzXln5ZsqpKMq6u/l/epHwY9l5brdb+eWkrievzvDiwlbS9ytTTXev+ZnxEFTfDQdi2g5g1OW7Suct6eYTzPKvj8NLclwd8UewdNqP/uRb12ZwVfjeO2aQ2fpnopsxjs4BNsz5aAzbOmeLE89m8NU4sCvBngNKhhAOg+JQQQhlTRAJlSBFvUNaUEQFIKSMH4FLGLTCjkBA4UsgSKCkkAXJW5bMWV2caWsM56wBeq7dQdB2aKxBq7bvdHY3t0NbcrVvbfHB0tPd43qeREgvdlnLr08ADAoMWpiujgegatJQopa4NYqOWMupTjwXHsKX0HqmjB5q34O3fWN3AvHUyabOybr2WUj6xoqxuS6k6kvcXaKFoOVLvpWyrxMKLtKqtmpcLtO5dkiLvNbZZ0RpOrdZUY3dmeKzRmg18VtfF3LyVu5qHr2vaimztOR+YtaYvBvsrMWnNTK6AHLZB69CngZHBHRWlNLLVvg+9kIYmWGm1Zmrvm/KQ6bRGfZoDetVbkw5riIENq3piLQEwopAUiCjkGSgopAsgpowNgAVlbAHcUUYBYEARLpQDRXSgeBSxBsQ+jiGg3FKCByg7CljiXcjmjfHOm7Npa7wTmPVJgZ/u2ahgCgjFEg9/iBI2JcMXh6XPywuyHc4qu89pEPMiHHexDK0j/mEalbk1aClhO13YJ0+uz79x5h+/z197m9H2WKCe3fjmk3Li4erqe/kBq1aXZoS4C4oAAAAASUVORK5CYII="
                            alt=""
                          />
                        );
                      }
                    })()}
                  </span>
                  YOUTUBE
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <OnlySP>
          <p className="footer__copyright">©POST-FAKE</p>
        </OnlySP>
        <p
          className="footer__page-top link__alpha"
          onClick={() => {
            window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
          }}>
          <img
            src={
              context.theme === ThemeColor.BLACK || context.theme === ThemeColor.DARK_GRAY
                ? data.pageTopWhite.publicURL
                : data.pageTop.publicURL
            }
            alt="PAGE TOP"
          />
        </p>
      </footer>

      <NewsLetter />
    </>
  );
};
export default React.memo(Footer);

const NewsLetter: React.FunctionComponent = () => {
  const [hasMail, setHasMail] = useState(false);

  if (typeof window !== 'undefined') {
    useEffect(() => {
      if (window.location.href.includes('newsletter&form_type=customer')) {
        setHasMail(true);
      }
    }, []);
  }
  if (!hasMail) return <></>;

  return (
    <div className="mail-modal">
      <p
        className="mail-modal__bg"
        onPointerDown={() => {
          setHasMail(false);
        }}></p>
      <div className="mail-modal__inner">
        <p className="mail-modal__caption ff-univ-bold">
          <i className="glyphs-mail"></i>
          NEWS LETTER
        </p>
        <p className="mail-modal__text ff-univ">Thanks for subscribing.</p>
        <p
          className="mail-modal__close ff-univ-bold"
          onPointerDown={() => {
            setHasMail(false);
          }}>
          <span className="link__text">
            <span>CLOSE</span>
          </span>
        </p>
      </div>
    </div>
  );
};
