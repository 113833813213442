export const PATH = {
  ROOT: '/',
  JOURNAL: '/journal/',
  JOURNAL_DETAIL: '/journal/detail/',
  JOURNAL_PAGE: '/journal/page/',
  ARTISTS: '/artists/',
  ARTISTS_DETAIL: '/artists/detail/',
  ARTISTS_PAGE: '/artists/page/',
  STORE: 'https://products.postfake.com/',
  STORE_PAGE: '/store/page/',
  STORE_DETAIL: '/store/detail',
  NEWS: '/news/',
  NEWS_DETAIL: '/news/detail/',
  NEWS_PAGE: '/news/page/',
  DICTIONARY: '/dictionary/',
  DICTIONARY_DETAIL: '/dictionary/detail/',
  SEARCH: `${process.env.WP_PATH}/wp-json/wp/v2/journal?_jsonp=callback&per_page=10&search=`,
  SEARCH_LIST: '/search/?word=',
  ABOUT: '/about/',
  MY_PAGE: `https://store.postfake.com/account`,
  GUIDE: `https://store.postfake.com/pages/guide`,
  FAQ: `https://store.postfake.com/pages/faq`,
  CONTACT: `https://store.postfake.com/pages/contact`,
  TERMS: `https://store.postfake.com/pages/terms`,
  PRIVACY: `https://store.postfake.com/pages/privacy`,
  LEGAL: `https://store.postfake.com/pages/legal`,
  MAILING_LIST: `https://store.postfake.com/pages/newsletter`,
  INSTAGRAM: 'https://www.instagram.com/post__fake/',
  TWITTER: 'https://twitter.com/post__fake',
  LINE: '#',
  YOUTUBE: 'https://www.youtube.com/channel/UCnE58_M8wwotJ8HOVxCVqLg'
};
