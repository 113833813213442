import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import { AppContext, IAppContext } from '../../context/context';
import { PATH } from '../../helper/config';

const SearchWindow: React.FunctionComponent = () => {
  const context = useContext<Partial<IAppContext>>(AppContext);
  const [targetClass, setTargetClass] = useState<string>('');
  const ref: React.RefObject<any> = useRef(null);

  useEffect(() => {
    setTargetClass('show');
  }, []);

  useEffect(() => {
    ref.current.focus();
  }, [ref.current]);

  const searchData = useStaticQuery(graphql`
    query searchWindowCategoryQuery {
      allWpGqlJournalCategoryItem {
        edges {
          node {
            slug
            name
          }
        }
      }
    }
  `);

  return (
    <div
      className={`search-window ${targetClass}`}
      onTransitionEnd={() => {
        if (targetClass === 'hide') {
          context.toggleSearchWindow(false);
        }
      }}>
      <div className="search-window__inner">
        <div className="search-window__form">
          <input
            type="search"
            ref={ref}
            onKeyDown={(event) => {
              if (event.keyCode === 13) {
                if (typeof window !== 'undefined') {
                  location.href = `${PATH.SEARCH_LIST}${ref.current.value}`;
                }
              }
            }}
          />
          <i
            className="glyphs-search"
            onPointerDown={() => {
              if (typeof window !== 'undefined') {
                location.href = `${PATH.SEARCH_LIST}${ref.current.value}`;
              }
            }}></i>
        </div>

        <ul className="search-window__category-list">
          {searchData.allWpGqlJournalCategoryItem.edges &&
            searchData.allWpGqlJournalCategoryItem.edges.map((elem: any, i: number) => {
              if (!elem.node.slug.includes('pf-product')) {
                return (
                  <li className="search-window__category-list-item" key={`search-window__category-list-${i}`}>
                    <Link to={`${PATH.JOURNAL}category/${elem.node.slug}`} className="link__text">
                      {elem.node.name}
                    </Link>
                  </li>
                );
              }
            })}
        </ul>
      </div>
      <p
        onClick={() => {
          setTargetClass('hide');
        }}
        className="search-window__close ff-univ-bold link__text">
        CLOSE
      </p>
    </div>
  );
};
export default React.memo(SearchWindow);
