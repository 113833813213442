import { createContext } from 'react';
import ScrollController from '../templates/utils/scroll-controller';

export enum ThemeColor {
  DEFAULT,
  BROWN,
  BLACK,
  WHITE,
  GRAY,
  DARK_GRAY
}

export interface ILanguage {
  ja: string;
  en: string;
}

export interface IAppContext {
  localePath: string;
  setLocalePath(param: string): void;
  isGlobalNavigation: boolean;
  toggleGlobalNavigation(param: boolean): void;
  isSearchWindow: boolean;
  toggleSearchWindow(param: boolean): void;
  isCartWindow: boolean;
  toggleCartWindow(param: boolean): void;
  movieId: string;
  isMovieModal: boolean;
  toggleMovieModal(movieId: string, isMovieModal: boolean): void;
  theme: number;
  setTheme(theme: number): void;
  cartId: string;
  setCartID(id: string): void;
  cartData: any;
  setCartData(cartData: any): void;
  language: ILanguage;
  locale: string;
  setLanguage(language: ILanguage, locale: string): void;
}
export const AppContext = createContext<Partial<IAppContext>>({});
