import React from 'react';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

export const OnlyPC: React.FunctionComponent<any> = (props) => {
  return <div className={`${props.classStr ? props.classStr : ''} only-pc`}>{props.children}</div>;
};

export const OnlySP: React.FunctionComponent<any> = (props) => {
  return <div className={`${props.classStr ? props.classStr : ''} only-sp`}>{props.children}</div>;
};

export const OnlyBreakPC: React.FunctionComponent<any> = (props) => {
  const breakPoints = useBreakpoint();
  return breakPoints.pc ? props.children : null;
};

export const OnlyBreakSP: React.FunctionComponent<any> = (props) => {
  const breakPoints = useBreakpoint();
  return breakPoints.mobile ? props.children : null;
};
