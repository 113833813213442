import React from 'react';

const ModalCloser: React.FunctionComponent<any> = (props) => {
  return (
    <div
      className="modal-closer"
      onPointerDown={() => {
        props.callback(false);
      }}></div>
  );
};
export default ModalCloser;
